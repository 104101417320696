import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

import { metadataAtom } from './jotai-atoms';
import { getMetadata } from './api-services';
import { displayErrorToast } from '~/components/ui/use-toast';

const useMetadata = () => {
  const [metadata, setMetadata] = useAtom(metadataAtom);

  const fetchMetadata = useCallback(async () => {
    try {
      const responseData = await getMetadata();
      setMetadata(responseData);
    } catch (error) {
      displayErrorToast(error);
    }
  }, [setMetadata]);

  useEffect(() => {
    if (metadata === null) {
      fetchMetadata();
    }
  }, [fetchMetadata, metadata]);

  // Constants shortcuts while metadata is null
  const cweMetadata = metadata?.constants.cwe || [];
  const owaspMetadata = metadata?.constants.owasp || [];
  const dataElementTagMetadata = metadata?.constants.dataElementTag || [];
  const dataElementSensitivityMetadata =
    metadata?.constants.dataElementSensitivity || [];
  const vulnerabilitySeverityMetadata =
    metadata?.constants.vulnerabilitySeverity || [];
  const sourceMetadata = metadata?.constants.source || [];
  const ropaAssociatedIndividualMetadata =
    metadata?.constants?.ropaAssociatedIndividual || [];
  const ropaDataSourceMetadata = metadata?.constants?.ropaDataSource || [];
  const ropaDataUsageMetadata = metadata?.constants?.ropaDataUsage || [];
  const ropaSecurityMeasureMetadata =
    metadata?.constants?.ropaSecurityMeasure || [];
  const ropaOrganizationRoleMetadata =
    metadata?.constants?.ropaOrganizationRole || [];
  const ropaLegalBasisMetadata = metadata?.constants?.ropaLegalBasis || [];
  const sanitizerTypeMetadata = metadata?.constants.sanitizerType || [];
  return {
    metadata,
    cweMetadata,
    owaspMetadata,
    dataElementTagMetadata,
    dataElementSensitivityMetadata,
    vulnerabilitySeverityMetadata,
    sourceMetadata,
    ropaAssociatedIndividualMetadata,
    ropaDataSourceMetadata,
    ropaDataUsageMetadata,
    ropaSecurityMeasureMetadata,
    ropaOrganizationRoleMetadata,
    ropaLegalBasisMetadata,
    sanitizerTypeMetadata,
  } as const;
};

export { useMetadata };
